export function getScreenName() {
  let pathname = getPathName();
  const searchPathName = getSearchPathName();
  let screenName = null;
  pathname = pathname.replace(
    // eslint-disable-next-line no-useless-escape
    /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/gim,
    '',
  );
  pathname = pathname.replace('/', '');
  pathname = pathname.split('?');
  pathname = pathname.length ? pathname[0] : '';
  if (pathname.includes('PaymentFailed')) {
    screenName = 'Payment Failed Page';
  } else if (pathname.includes('PaymentSuccess')) {
    screenName = 'Payment Success Page';
  } else if (pathname.includes('payment-waiting')) {
    screenName = 'Payment Waiting Page';
  } else if (pathname.includes('promotion')) {
    screenName = 'Promotion Page';
  } else if (pathname.includes('just-arrived')) {
    screenName = 'Just Arrived Page';
  } else if (pathname.includes('quick-registration')) {
    screenName = 'Quick Registration Page';
  } else if (pathname.includes('best-seller')) {
    screenName = 'Best Seller Page';
  } else if (pathname.includes('flash-sale')) {
    screenName = 'Flash Sale Page';
  } else if (pathname.includes('_')) {
    screenName = 'Brand Detail Page';
  } else if (pathname.includes('sign-up')) {
    screenName = 'Sign Up Page';
  } else if (pathname.includes('promo') && !pathname.includes('/')) {
    screenName = 'Promotion Page';
  } else if (pathname.includes('my-profile')) {
    screenName = 'My Profile Page';
  } else if (pathname.includes('my-orders')) {
    screenName = 'My Orders Page';
  } else if (
    (pathname.includes('-') && !pathname.includes('/')) ||
    pathname.includes('promo/')
  ) {
    const regexResult = pathname.split('/');
    const regexQueryParamsResult = searchPathName.match(/type=([^&]*)/);
    if (
      regexResult &&
      Array.isArray(regexResult) &&
      regexResult.length > 0 &&
      regexQueryParamsResult &&
      Array.isArray(regexQueryParamsResult) &&
      regexQueryParamsResult.length > 0
    ) {
      screenName = 'Category Detail Page';
    }

    if (pathname.includes('promo/')) {
      screenName = 'Category Promo Page';
    }
  } else if (pathname.includes('-') && pathname.includes('/')) {
    screenName = 'Product Detail Page';
  } else if (pathname.includes('order')) {
    screenName = 'Shopping Bage Page';
  } else if (
    pathname.includes('Shop') &&
    pathname.includes('OrderDetailScreen') &&
    pathname.includes('/')
  ) {
    screenName = 'Order Detail Page';
  } else {
    screenName = 'Homepage';
  }
  return screenName;
}

function getPathName() {
  return window.location.pathname;
}

function getSearchPathName() {
  return window?.location?.search;
}
