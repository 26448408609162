import AsyncStorage from '@react-native-async-storage/async-storage';

import { getListProductsInfo, getCombinationInfo } from '../helpers/product.js';
import { getScreenName } from '../helpers/route';
import ENV from '../env.js';
import { Analytics } from '~api/analytics';
import { getUtmData } from '~global/helpers';
import { isNative } from '~global/screens';
import { Dimensions, Platform } from 'react-native';
const $get = require('lodash.get');
const Config = require('~config/default.env').default;

import DeviceInfo from 'react-native-device-info';

const { height: screenHeight, width: screenWidth } = Dimensions.get('window');

const getDeviceInfo = async () => {
  try {
    const deviceOS = await DeviceInfo.getBaseOs();
    const deviceSDKVersion = DeviceInfo.getSystemVersion();
    const Brand = DeviceInfo.getBrand();
    const Model = DeviceInfo.getModel();

    return { deviceOS, deviceSDKVersion, Brand, Model };
  } catch (error) {
    console.error('Error getting device OS:', error);
  }
};

const InternalTracking = {
  payload: {},
  eventName: '',
  isLoggedIn: null,

  async getUserData() {
    const userData = JSON.parse(await AsyncStorage.getItem('userData'));

    return userData;
  },
  async checkUserLoginSession() {
    if (isLoggedIn) {
      return this.isLoggedIn;
    }
    const userData = JSON.parse(await AsyncStorage.getItem('userData'));
    const isLoggedIn = userData ? true : false;
    this.isLoggedIn = isLoggedIn;
    return isLoggedIn;
  },
  async getGuildLilla() {
    const guild = await AsyncStorage.getItem('trackingGuidLilla');
    return guild;
  },
  async generateGuild() {
    let d = new Date().getTime();
    let newGuid = '';
    const guid = await this.getGuildLilla();
    const appSessionState = await AsyncStorage.getItem('@appSessionState');
    if (!guid || appSessionState) {
      if (
        typeof performance !== 'undefined' &&
        typeof performance.now === 'function'
      ) {
        d += performance.now(); //use high-precision timer if available
      }
      newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          const r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
        },
      );
      await AsyncStorage.setItem('trackingGuidLilla', newGuid);
      await AsyncStorage.removeItem('@appSessionState');
    } else {
      newGuid = guid;
    }
    return newGuid;
  },
  // init(user) {
  // 	let isLoggedIn = this.checkUserLoginSession();
  // 	if (this.getGuildLilla()) {
  // 		return false;
  // 	}
  // 	this.generateGuild();
  // 	let payload = {
  // 		session_id: this.getGuildLilla(),
  // 	};
  // 	if (isLoggedIn) {
  // 		payload.user_id = user._id;
  // 		payload.email = user.email;
  // 		payload.user_name = user.user_name;
  // 		payload.event = {
  // 			name: "initial_load",
  // 		}
  // 	}
  // 	Analytics.setSessionMe(payload)
  // 		.then((response) => {
  // 			if (response.status === 200) {
  // 				this.setEvent({
  // 					verb: 'open',
  // 					object_type: 'lilla',
  // 				})
  // 			}
  // 		})
  // },
  async addToCart(payload) {
    const userData = await this.getUserData();

    const combinationInfo = getCombinationInfo(payload);
    const packDetail = payload.payload?.product?.is_pack
      ? payload.payload?.product?.pack
      : [];
    const dataAddToCart = {
      event_version: '1.0.0',
      utm_campaign: getUtmData('utm_campaign'),
      utm_medium: getUtmData('utm_medium'),
      utm_source: getUtmData('utm_source'),
      events: {
        name: 'addToCart',
        verb: 'add',
        object_id: payload._id,
        object_type: 'cart',
        referrer: {
          id: payload._id,
          object_type: getScreenName(),
          page_source: getScreenName(),
        },
        target: {
          id: payload._id,
          object_type: 'product',
          combination_id: combinationInfo?.combinationId,
        },
        event_data: {
          product: {
            name: payload?.name ?? '',
            id: payload?.id ?? '',
            combination_id: combinationInfo?.combinationId,
            brand: payload?.brand?.name,
            classification: payload?.classification,
            category_parent: $get(payload, 'categories[0].name', ''),
            category_child: $get(payload, 'categories[1].name', ''),
            category_grandchild: $get(payload, 'categories[2].name', ''),
            combination_name: combinationInfo?.combinationName,
            pack_detail: packDetail,
            currency: 'IDR',
            price:
              payload?.default_combination?.price_after_discount ||
              payload?.default_combination?.price,
            quantity: payload.payload?.product?.quantity ?? '',
            ean: payload?.default_combination?.ean_no,
            reference: payload?.default_combination?.reference,
          },
        },
      },
      country: userData?.user?.locale,
    };
    this.setEvent(dataAddToCart);
  },

  async reorder(cartId) {
    const dataReorder = {
      events: {
        verb: 'reorder',
        name: 'reorder',
        object_id: cartId,
        object_type: '',
        referrer: {
          id: cartId,
          object_type: 'MyOrders',
        },
        target: {
          id: cartId,
          object_type: 'cart',
        },
      },
    };

    this.setEvent(dataReorder);
  },
  async deleteCart(product) {
    const dataDeleteCart = {
      events: {
        verb: 'remove',
        object_id: product?.combination_id,
        object_type: 'cart',
        referrer: {
          id: product?.combination_id,
          object_type: 'cart',
        },
        target: {
          id: product?.id,
          object_type: 'product',
          combination_id: product?.combination_id,
        },
        action: 'minus',
      },
    };
    this.setEvent(dataDeleteCart);
  },

  async addToWishlist(payload) {
    const userData = await this.getUserData();

    const dataAddToWishlist = {
      event_version: '1.0.0',
      utm_campaign: getUtmData('utm_campaign'),
      utm_medium: getUtmData('utm_medium'),
      utm_source: getUtmData('utm_source'),
      events: {
        verb: payload.is_wishlisted ? 'add' : 'remove',
        object_id: '',
        object_type: 'wishlist',
        referrer: {
          id: '',
          object_type: getScreenName(),
        },
        target: {
          id: payload.product_id,
          object_type: 'product',
          combination_id: payload.product_combination_id,
        },
        name: payload.is_wishlisted ? 'add' : 'remove',
      },
      country: userData?.user?.locale,
    };
    this.setEvent(dataAddToWishlist);
  },
  async search(payload) {
    const userData = await this.getUserData();

    const dataSearch = {
      event_version: '1.0.0',
      utm_campaign: getUtmData('utm_campaign'),
      utm_medium: getUtmData('utm_medium'),
      utm_source: getUtmData('utm_source'),
      events: {
        name: 'search',
        referrer: {
          id: userData?.user._id,
          object_type: getScreenName(),
        },
        event_data: {
          keyword: payload.text,
          enter_search_result: payload.searchResult,
          total_search_result: payload.total_search_result,
          suggested_brand_click: Boolean(payload?.suggested_brand_click),
          recently_search_click: Boolean(payload?.recently_search_click),
          suggested_product_click: Boolean(payload?.suggested_product_click),
          //
        },
      },
      country: userData?.user?.locale,
    };
    this.setEvent(dataSearch);
  },
  completeRegistration(user) {
    const dataCompleteRegistration = {
      event_version: '1.0.0',
      utm_campaign: getUtmData('utm_campaign'),
      utm_medium: getUtmData('utm_medium'),
      utm_source: getUtmData('utm_source'),
      gender: user.gender,
      email: user.email,
      user_id: user._id,
      name: user.name,
      user_name: user.user_name,
      events: {
        name: 'completeRegistration',
        referrer: {
          id: user._id,
          object_type: getScreenName(),
        },
        event_data: {
          user_id: user._id,
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          mobile_number: user.phone_no_e164,
          country: user.locale,
          gender: user.gender,
          registration_method: 'lilla_register',
          store_id: user._id,
        },
      },
      country: user?.locale,
    };
    this.setEvent(dataCompleteRegistration);
  },
  async viewContent(payload) {
    const userData = await this.getUserData();

    const combinationInfo = getCombinationInfo(payload);
    const categoryParent = $get(payload, 'categories[0].name', '');
    const categoryChild = $get(payload, 'categories[1].name', '');
    const categoryGrandChild = $get(payload, 'categories[2].name', '');
    const combinationId = payload?.default_combination?._id
      ? payload.default_combination['_id']
      : '';
    const packDetail = payload?.pack_detail?.length
      ? payload.pack_detail.map((product) => {
          return {
            product_id: product.id,
            combination_id: product.combinations?.[0]?._id,
            quantity: product.quantity,
          };
        })
      : [];

    const dataViewContent = {
      event_version: '1.0.0',
      utm_campaign: getUtmData('utm_campaign'),
      utm_medium: getUtmData('utm_medium'),
      utm_source: getUtmData('utm_source'),
      events: {
        name: 'viewProduct',
        object_id: payload['_id'],
        object_type: 'product',
        referrer: {
          id: payload.id,
          object_type: getScreenName(),
        },
        target: {
          id: payload.id,
          object_type: payload.classification,
          combination_id: combinationId,
        },
        event_data: {
          product: {
            id: payload?.id,
            name: payload?.name,
            brand: payload?.brand?.name,
            combination_name: combinationInfo?.combinationName,
            combination_id: combinationId,
            pack_detail: packDetail,
            classification: payload?.classification,
            price:
              payload?.default_combination?.price_after_discount ||
              payload?.default_combination?.price,
            category_child: categoryChild,
            category_parent: categoryParent,
            category_grandchild: categoryGrandChild,
            out_of_stock: !payload.is_in_stock,
            ean: payload?.default_combination?.ean_no,
            reference: payload?.default_combination?.reference || '-',
          },
          //
        },
      },
      country: userData?.user?.locale,
    };
    this.setEvent(dataViewContent);
  },
  async purchase(payload) {
    const userData = await this.getUserData();

    const voucherAutomatics = payload?.voucher_automatic?.length
      ? payload?.voucher_automatic.map((i) => {
          const voucher = {
            id: i.id.toString(),
            name: i.name.toString(),
            type: i.type.toString(),
            reduction_percentage: i.reduction_percentage.toString(),
            reduction_amount: i.reduction_amount.toString(),
            extra_voucher_sp: i.extra_voucher_sp.toString(),
          };
          return voucher;
        })
      : '-';

    const extractProducts = getListProductsInfo(payload.cartDetail.products);
    const productReference = getListProductsInfo(payload.products);

    const dataViewContent = {
      event_version: '1.0.0',
      utm_campaign: getUtmData('utm_campaign'),
      utm_medium: getUtmData('utm_medium'),
      utm_source: getUtmData('utm_source'),
      events: {
        name: 'purchase',
        verb: 'purchase',
        object_id: payload['_id'],
        object_type: 'product',
        referrer: {
          id: payload.cart_id,
          object_type: getScreenName(),
        },
        user_name: payload.user.user_name,
        gender: payload.user.gender,
        soc_platform: payload.order_source,
        app_version: payload.app_version,
        event_data: {
          id: extractProducts.cart_id,
          object_type: 'purchase',
          product: {
            id: extractProducts.productsId.join(', '),
            name: extractProducts.productsName.join(', '),
            brand: extractProducts.brands.join(', '),
            combination: extractProducts.combinationsName.join(', '),
            category_parent: extractProducts.categoriesParent.join(', '),
            category_child: extractProducts.categoriesChild.join(', '),
            category_grand_chhild:
              extractProducts.categoriesGrandChild.join(', '),
            classification: extractProducts.classifications.join(', '),
            reference: productReference.referenceCode.join(', '),
            ean: productReference.eanNo.join(', '),
            quantity: extractProducts.quantities.join(', '),
            unit_price_after_discount:
              extractProducts.unitpPriceAfterDiscounts.join(', '),
            unit_price: extractProducts.unitpPrices.join(', '),

            product_price_rule_id: extractProducts.priceRulesIds.join(', '),
            product_price_rule_name: extractProducts.priceRulesNames.join(', '),
            discount_from_voucher:
              extractProducts.discountfromVouchers.join(', '),
            free_products: payload.free_products,
          },

          currency: 'IDR',
          total_paid: payload.total_paid,
          transaction_id: payload._id,
          //order data
          price: payload.price,
          total_discount: payload.total_discount,
          total_point: payload.convenience_fee,
          price_after_discount: payload.price_after_discount,
          cart_id: payload.cart_id,
          order_type: payload.order_type,
          order_source: payload.order_source,
          total_shipping: payload.total_shipping,
          total_shipping_discount: payload.total_shipping_discount,
          total_original_shipping: payload.total_original_shipping,
          total_extra_charge: payload.total_extra_charge,
          total_amount_after_extra: payload.total_amount_after_extra,
          total_quantity: payload.total_quantity,
          is_order_reseller: payload.is_order_reseller,
          total_saving: payload.total_saving,
          is_sbwe: payload.is_sbwe,
          is_event: payload.is_event,
          is_beauty_exchange: payload.is_beauty_xchange,
          is_clearence: payload.is_clearance,
          is_payment_method_change: payload.is_payment_method_change,
          shipping: {
            address_id: payload.shipping?.id,
            address_type: payload.shipping?.delivery_type,
          },

          //payment method data
          payment_method: {
            code: payload.payment_method?.code,
            id: payload.payment_method?.id,
            name: payload.payment_method?.name,
          },

          //voucher
          voucher_applied: {
            id: payload?.voucher_applied?.id,
            code: payload.voucher_applied?.code,
            name: payload?.voucher_applied?.name,
            amount: payload?.voucher_applied?.amount,
            reduction_amount: payload?.voucher_applied?.reduction_amount,
            reduction_percentage:
              payload?.voucher_applied?.reduction_percentage,
            discount_by_sociolla:
              payload?.voucher_applied?.discount_by_sociolla,
            discount_by_external:
              payload?.voucher_applied?.discount_by_external,
            type_of_voucher: payload?.voucher_applied?.type_of_voucher,
          },
          voucher_automatic: voucherAutomatics,

          free_shipping_applied_voucher:
            payload.cartDetail.applied_voucher_free_shipping,
          extra_bag_rules: payload.extra_bag_rules,
        },
      },
      country: userData?.user?.locale,
    };
    this.setEvent(dataViewContent);
  },
  async setEvent(payload) {
    const deviceInfo = await getDeviceInfo();
    const device_data = {
      user_agent: !isNative ? navigator.userAgent : '-',
      screen_height: screenHeight,
      screen_width: screenWidth,
      os: deviceInfo.deviceOS,
      environment: Config.ENVIRONMENT,
      ...(isNative && {
        platform: Platform.OS,
        os_version: Platform.Version,
        sdk_version: deviceInfo?.deviceSDKVersion,
        brand: deviceInfo?.Brand,
        model: deviceInfo?.Model,
      }),
    };
    const isLoggedIn = await this.checkUserLoginSession();
    if (isLoggedIn) {
      await this.generateGuild();
      const guild = await this.getGuildLilla();
      Analytics.postSessionMe({ guild, payload: { ...payload, device_data } });
    }
  },
  debug() {
    if (ENV.debug) {
      if (this.payload && Object.keys(this.payload).length) {
        console.log(`-----Event ${this.eventName} Data-----`);
        for (const key in this.payload) {
          console.log(`${key} => ${this.payload[key]}`);
          if (typeof this.payload[key] === 'object') {
            for (const keyChild in this.payload[key]) {
              console.log(`${keyChild} => ${this.payload[key][keyChild]}`);
            }
          }
        }
        console.log('-----EndDebug-----');
      }
    }
    return this;
  },
};

export default InternalTracking;
