// moengage api interface
export default {
  payload: {},
  eventName: '',
  trackEvent() {},
  addToCart() {},
  addToWishlist() {},
  login() {},
  purchase() {},
  orderStatus() {},
  search() {},
  viewContent() {},
  viewCategories() {},
  viewBrands() {},
  completeRegistration() {},
  registration() {},
  debug() {},
};
